<template>
  <div class="news-page">
    <Page
      :current="page.pageNo"
      :page-size="page.pageSize"
      :total="page.total"
      class="xpage myPage"
      @on-change="pageChange"
    ></Page>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    page: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    pageChange(pageNo) {
      this.$emit("pageChange", pageNo);
    },
  },
};
</script>

<style scoped>
.myPage >>> .ivu-page-prev,
.myPage >>> .ivu-page-next {
  width: 102px;
  height: 42px;
  line-height: 42px;
  border-radius: 0%;
}
.myPage >>> .ivu-page-item {
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 0%;
}
.myPage >>> li:hover {
  border-color: #dcdee2;
}
.myPage >>> .ivu-page-item-active {
  border-color: #ff9c00 !important;
  background-color: #ff9c00 !important;
  border-radius: 4px;
}
.myPage >>> .ivu-page-item a {
  color: #999999;
}
.myPage >>> .ivu-page-item-active a {
  color: #ffffff;
}
.myPage >>> .ivu-page-prev:hover,
.myPage >>> .ivu-page-next:hover {
  background-color: #ff9c00 !important;
}
.myPage >>> .ivu-page-prev:hover a,
.myPage >>> .ivu-page-next:hover a {
  color: #ffffff;
}
.myPage >>> .ivu-page-disabled:hover {
  background-color: transparent !important;
}
.myPage >>> .ivu-page-disabled:hover a {
  color: #CCCCCE;
}
</style>