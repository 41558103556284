<template>
  <div class="outer-page">
    <div class="warpper">
      <div class="news-center">
        <ul>
          <li
            :class="index == 0 ? '' : 'oLi'"
            v-for="(item, index) in newsList"
            :key="index"
          >
            <div class="news-top" v-if="index == 0">
              <a @click="goThere(item)">
                <img :src="item.servicePath + item.cover" alt="" />
              </a>
              <div class="news-info">
                <a @click="goThere(item)" class="news-title">{{ item.name }}</a>
                <span>{{ item.releaseDate ? item.releaseDate.split(' ')[0] : item.createDate }}</span>
                <a class="more" @click="goThere(item)">MORE+</a>
              </div>
              <div class="clears"></div>
            </div>
            <a v-if="index !== 0" @click="goThere(item)">
              <p>{{ item.name }}</p>
              <span>{{ item.releaseDate ? item.releaseDate.split(' ')[0] : item.createDate }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="news-page">
        <page-view :page="page" @pageChange="pageChange"></page-view>
      </div>
    </div>
  </div>
</template>

<script>
import pageView from "@components/pageView";
import moment from "moment";
export default {
  name: "",
  components: {
    pageView,
  },
  data() {
    return {
      newsList: [],
      //分页
      page: {
        pageNo: 1,
        pageSize: 21,
        total: 0,
      },
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  watch: {
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
  },
  methods: {
    goThere(item) {
      this.$router.push({
        name: "newsInfo",
        query: {
          id: item.id,
          key: this.$route.name
        },
      });
    },
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: this.page.pageSize,
          pageNo: this.page.pageNo,
        }).then((res) => {
          this.newsList = res.list;
          this.page.total = +res.total;
          for (let i = 0; i < this.newsList.length; i++) {
            this.newsList[i].createDate = moment(
              this.newsList[i].createDate
            ).format("YYYY-MM-DD");
          }
        });
      }
    },
    //分页
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
